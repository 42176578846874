<template>
  <div class="newslist">
      <ul class="workinfo-list">
            <li 
            v-for="(item,index) in list" 
            :key="index"
            @click="openNewTag(item.id)"
            >
                <p :class="{'active': item.type === 'downloadfile'}">
                <!-- 2020年高考防疫家长关键提示10条 -->
                {{item.title}}
                <i class="loaddown" v-if="item.type === 'downloadfile'"></i>
                </p>
                <span>{{item.publishDate?item.publishDate:'' | formateData}}</span>
            </li>
        </ul>
        <div class="pagination">
        <el-pagination
        class="bs-pagination"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
        </div>
  </div>
</template>

<script>
import apiUrls from '@api'
export default {
  name: 'Newslist',
  props: {
        list : {
          type: Array,
          default: () => []
        },
        nodename: {
          type: String,
          default: () => "tzgg"
        }
        //   default: [
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: "downloadfile"
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: ""  
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: "downloadfile"
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: ""  
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: "downloadfile"
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: ""  
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: "downloadfile"
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: ""  
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: "downloadfile"
        //     },
        //     {
        //     content: "2020年高考防疫家长关键提示10条",
        //     type: ""  
        //     },
        //     ]
        // {
        // content: "2020年高考防疫家长关键提示10条",
        // type: "downloadfile"
        // },
        // {
        // content: "2020年高考防疫家长关键提示10条",
        // type: "downloadfile"  
  
  },
    data () {
      return {
      page: 1,
      size: 10,
      total: 0,
      newslist2: []
      }
    },
    created() {
        this.handleCurrentChange()
    },
  methods : {
    handleSizeChange(val) {

    },
    handleCurrentChange(val = 0) {
      apiUrls.selfDynamicList({node: this.nodename, page: val}).then(res=>{
      this.total = res.results.pager.totalElements;
      this.newslist2= res.results.data
      this.$emit('childByValue', this.newslist2, this.nodename)
      })
    },
    openNewTag(id){
      const {href} = this.$router.resolve({
        name: 'detailShow',
        query: {
          id:id
        }
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.workinfo-list {
        cursor: pointer;
        li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
			padding: 10px 0;
            &:last-child{
                margin-bottom: 0;
            }
            p{
                color: #333333;
				&:hover {
					color: #d1292e;
				}
                display: flex;
                &::before {
                    content: "";
                    display: block;
                    height: 16px;
                    width: 26px;
                    background: url('../assets/img/list-little.png') no-repeat center center;
                }
            }
            span {
                color: #ABABAB;
            }
            .loaddown {
                width: 18px;
                height: 16px;
                margin-left: 10px;
                background: url('../assets/img/download.png') no-repeat center center;
            }
            .active {
                color: #1F6CA2;
            }
        }
    }
    .pagination {
            margin-top: 40px;
            .bs-pagination {
             width: 656px;
            }
        }
</style>
